/* eslint no-console:0 */

// CSS
import 'auth/styles'

// JS
import 'shared/rails'
import 'shared/jquery'
import "bootstrap"

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})
